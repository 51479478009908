<template>
  <div class='footer-links'>
  <h2>About Us</h2>
    <p>
    This website provides a quick and easy way for a parent to apply The Montessori Method,
Waldorf - inspired education, Magda Gerber's RIE philosophy and to use the Froebel gifts.
For questions concerning this website, please <a id='contact-us' :href='"/#/contact"'>contact us.</a>
    </p>
    <ul>Here's what you can expect on this website:
    <li>Short articles to acquaint you with the principles, methods, philosophy and practice of
    the above mentioned educational methods.</li>
    <li>A simple way to start and easy-to-follow steps at your child's own pace.</li>
    <li>Progress that completely depends on your child's interests.</li>
    <li>Time-saving self education, you only need a couple of minutes to read and prepare for
    the next exercise.</li>
    <li>Over 400 activities for structured goal-oriented play and a lot of time for free creative
     open-ended play.</li>
    <li>Ideas for inexpensive DIY materials in accordance with the Montessori Method of education
     and Froebel gifts.</li>
<li>There are NO ads on our website.</li>
    </ul>
    <p>
      All activities are screen free for the child. We've created a lot of printable materials to save
      you the endless and frustrating process of searching through websites and blogs for something
      that may provoke your child's interest. </p>
<p>There are five free activities in each section. If you like to get an unlimited access to our resources,
please visit <a href = "/#/membership" class = "red_link">Register Your "A Kindergarten" Account.</a> The content of this website cannot be shared. You are responsible for keeping your password secret and secure. You may not use this website and its content for any unautorized purpose. You agree to comply with all laws, rules, and regulations (for example, state, local) aplicable to your use of the website and its content including, but not limited to, copyright laws. The sharing or selling of username(s) is strictly prohibited and may result in the removal of a user's account from the system.</p>
<p>You can also find us at <a :href = '"https://flurly.com/s/akindergarten"'> Flurly </a>.</p>
  </div>
</template>
<script>
export default {
  name: 'About'
}
</script>
<style>
</style>
