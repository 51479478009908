<template>
  <main>
    <section>
      <div role="tablist" class="mx-1">
        <b-card no-body class="main-btn red my-2" style="overflow: auto;">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.practical variant="default" style="color: white">Practical Life</b-btn>
          </b-card-header>
          <b-collapse id="practical" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Practical'" v-bind:color="'red'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn orange my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.math variant="default" style="color: white">Math</b-btn>
          </b-card-header>
          <b-collapse id="math" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Math'" v-bind:color="'orange'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn yellow my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.language variant="default" style="color: white">Language</b-btn>
          </b-card-header>
          <b-collapse id="language" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Language'" v-bind:color="'yellow'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn green my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.gross variant="default" style="color: white">Gross Motor Skills</b-btn>
          </b-card-header>
          <b-collapse id="gross" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Gross'" v-bind:color="'green'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn blue my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.senses variant="default" style="color: white">Sensorial Activities</b-btn>
          </b-card-header>
          <b-collapse id="senses" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Senses'" v-bind:color="'blue'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn violet my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.architecture variant="default" style="color: white">Architecture</b-btn>
          </b-card-header>
          <b-collapse id="architecture" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Architecture'" v-bind:color="'violet'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn brown my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.crafts variant="default" style="color: white">Arts and crafts</b-btn>
          </b-card-header>
          <b-collapse id="crafts" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Crafts'" v-bind:color="'brown'"></ExerciseInstance>
          </b-collapse>
        </b-card>
        <b-card no-body class="main-btn gray my-2">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-btn block href="#" v-b-toggle.rhythm variant="default" style="color: white">Music</b-btn>
          </b-card-header>
          <b-collapse id="rhythm" accordion="my-accordion" role="tabpanel">
            <ExerciseInstance v-bind:exercises="exercises" v-bind:type="'Rhythm'" v-bind:color="'gray'"></ExerciseInstance>
          </b-collapse>
        </b-card>
      </div>
    </section>
  </main>
</template>
<script>
import ExerciseInstance from '@/components/ExerciseInstance'

export default {
  name: 'Exercises',
  computed: {
    ex_types () {
      return this.$store.state.ex_types
    },
    exercises () {
      return this.$store.state.exercises
    }
  },

  components: {
    ExerciseInstance
  },
  mounted: function () {
    this.$store.dispatch('LOAD_FREE_EXERCISE_LIST')
    if (this.$store.state.auth.isAuthenticated) {
      var payload = {'token': this.$store.state.auth.token}
      this.$store.dispatch('LOAD_CURRENT_EXERCISE_LIST', payload)
    }
  },
  methods: {
    buildExerciseList (data) {
      this.exercises = data
    }
  }
}
</script>
<style>
</style>
