<template>
  <div>
    <div id="registration-form">
      <h1>Register Your "A Kindergarten" Account</h1>
      <p>We offer a continuous two year subscription. The purchaser has access to all resources on this website for a one - time fee of 15 USD.
         More information <a :href="'/#/about'" class="red_links" id="inlined-links">here</a>.</p>
      <form @submit="onSubmit" @reset="onReset">
        <input
          name="email"
          type="email"
          v-model="form.email"
          placeholder="Email address"
          autofocus>
        <input
          name="password"
          type="password"
          v-model="form.password1"
          placeholder="Password">
        <input
          name="password"
          type="password"
          v-model="form.password2"
          placeholder="Password confirmation">
      <div>
        <b-form-checkbox-group v-model="form.checked" id="exampleChecks">
          <b-form-checkbox value="agreed">I agree with the<a class="red_links" :href="'/#/privacy'"> Terms and Conditions and the Privacy Policy</a></b-form-checkbox>
        </b-form-checkbox-group>
      </div>
      </form>
    </div>
      <paypal
        v-on:payment-authorized.native="paymentAuthorized"
        v-on:payment-completed.native="paymentCompleted"
        v-on:payment-cancelled.native="paymentCancelled"
        v-bind:username="form.email"
        v-bind:password="form.password2">
      </paypal>
    <b-alert variant="danger" class="mt-2"
      dismissible
      :show="form.showAlert1"
      @dismissed="form.showAlert1=false">
      Password doesn't match!
    </b-alert>
  </div>
</template>
<script>
import axios from 'axios'
import paypal from '@/components/PayPal'

export default {
  name: 'Membership',
  data () {
    return {
      form: {
        email: '',
        password1: '',
        password2: '',
        showAlert1: false,
        checked: []
      }
    }
  },
  computed: {
    read_and_agreed () {
      if (this.form.checked.length === 1) {
        return true
      } else {
        return false
      }
    }
  },
  components: {
    paypal
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      if (this.form.password1 !== this.form.password2) {
        this.form.showAlert1 = true
      }
      axios.post('/api/registration', { username: this.form.email1, password: this.form.password1 })
        .then(request => {
          console.log('we are fucking here!!!')
          if (typeof request.data.access_token !== 'undefined') {
            this.executeLogin(request.data.access_token)
          } else {
            /* Reset our form values */
            this.form.email1 = ''
            this.form.email2 = ''
            this.form.password1 = ''
            this.form.password2 = ''
            this.form.checked = []
          }
        })
        .catch(error => { console.log(error) })
    },
    onReset (evt) {
      evt.preventDefault()
      /* Reset our form values */
      this.form.email = ''
      this.form.password1 = ''
      this.form.password2 = ''
      this.form.checked = []
    },
    executeLogin (token) {
      var payload = {'access_token': token}
      console.log(payload)
      this.$store.dispatch('set_token', payload)
      this.$store.dispatch('login')
      this.$router.push({name: 'home'})
    }
  }
}
</script>
<style>
</style>
