<template>
  <div>
    <div id="sign-form">
      <h1>Sign in or <a class="red_links" :href="'/#/membership'">Register Your "A Kindergarten" Account</a></h1>
      <form @submit="onSubmit" @reset="onReset">
        <input id="emailInput"
              name="email"
              type="email"
              placeholder="Email"
              v-model="form.email"
              required
              autofocus>
        <input id="passwordInput"
              name="password"
              type="password"
              placeholder="Password"
              v-model="form.password"
              required>
        <button name="submitBtn" type="submut" value="signin">Sign In</button>
        <a class="red_links" :href="'/#/reset'">Forgot your password?</a>
      </form>
    </div>
      <b-alert variant="danger" class="mt-2"
        dismissible
        :show="form.showAlert"
        @dismissed="form.showAlert=false">
        Username and/or password invalid!
      </b-alert>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: '',
        checked: [],
        showAlert: false
      }
    }
  },
  computed: {
    read_and_agreed () {
      if (this.form.checked.length === 2) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      axios.post('/api/login', { 'username': this.form.email, 'password': this.form.password })
        .then(request => {
          if (typeof request.data.access_token !== 'undefined') {
            this.executeLogin(request.data.access_token)
          } else {
            /* Reset our form values */
            this.form.email = ''
            this.form.password = ''
            this.form.checked = []
            this.form.showAlert = true
          }
        })
        .catch(error => { console.log(error) })
    },
    onReset (evt) {
      evt.preventDefault()
      /* Reset our form values */
      this.form.email = ''
      this.form.password = ''
      this.form.checked = []
    },
    executeLogin (token) {
      var payload = {'access_token': token}
      console.log(payload)
      this.$store.dispatch('set_token', payload)
      this.$store.dispatch('login')
      this.$router.push({name: 'home'})
    }
  }
}
</script>
<style>
</style>
