<template>
  <div class='footer-links'>
  <h3>Literature:</h3>
  <p>This is our recommended list of books on early childhood education:</p>
    <ol><p>Books by Maria Montessori</p>
      <li>The Montessori Method</li>
      <li>Dr. Montessori's Own Handbook</li>
      <li>The Absorbent Mind</li>
      <li>The Advanced Montessori Method</li>
      <li>The Montessiri Elementary Material</li>
      <li>The Discovery Of The Child</li>
      <li>Spontaneous Activity In Education</li>
      <li>The Mother And The Child</li>
      <li>The Child In The Family</li>
      <li>To Educate The Human Potential</li>
    </ol>

    <ol><p>Books by Friedrich Froebel</p>
      <li>The Education Of Man</li>
      <li>Pedagogics Of The Kindergarten</li>
      <li>Education By Development</li>
      <li>Froebel's Letters Of The Kindergarten</li>
    </ol>

    <ol><p>Books by Magda Gerber</p>
      <li>The RIE Manual: For Parents And Professionals</li>
      <li>Your Self-Confident Baby (This book is not available at <a href="https://archive.org" class = "red_links">archive.org</a>)</li>
    </ol>

  <p>All these useful and free resources in English can be found at <a href="https://archive.org" class = "red_links">archive.org</a>. Here parents can find eBooks on Montessori, Froebel and Waldorf education that can be downloaded to a PC or electronic reading devices.</p>
  </div>
</template>
<script>
export default {
  name: 'Literature'
}
</script>
<style>
</style>
