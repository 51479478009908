const types = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT'
}

// initial state
const state = {
  isAuthenticated: false,
  token: ''
}

// getters
const getters = {
  isAuthenticated: state => state.isAuthenticated
}

// actions
const actions = {
  login ({ commit, state }) {
    commit(types.LOGIN)
  },
  logout ({ commit, state }) {
    commit(types.LOGOUT)
  },
  set_token: function ({ commit, state }, payload) {
    const AuthStr = 'Bearer '.concat(payload.access_token)
    commit('SET_TOKEN', AuthStr)
  },
  clear_token: function ({ commit, state }) {
    commit('CLEAR_TOKEN')
  }
}

// mutations
const mutations = {
  [types.LOGIN] (state) {
    state.isAuthenticated = true
  },
  [types.LOGOUT] (state) {
    state.isAuthenticated = false
  },
  SET_TOKEN (state, authstr) {
    state.token = authstr
  },
  CLEAR_TOKEN (state) {
    state.token = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
