<template>
  <section>
  <div class="card-body">
    <h1>This section provides theme based learning resources (unit studies).</h1>
      <p class="card-text">A unit study incorporates many subjects into a study of one main topic. The child learns through handson activities corresponding to his level and interests. This thematic unit or integrated study combines history, math, literature, arts, geography, science and other subjects and provides an in-depth exploration in a way that helps the child remember information and develop critical thinking and love for learning. This method is more like learning from real life experience and enables the holistic development of children, a well-known Waldorf principle of education.</p>
      <p class="card-text">It is helpful to set a daily routine for the child, because it establishes healthy habits: self-discipline, good time-management, tasks completion, independence, confidence. The child has periods of concentration when his attention is directed to a specific activity, and periods of expansion (for free play). Through observation, the parent can learn to recognize and use these periods.</p>
      <p class="card-text">Each unit study pack includes:</p>
      <ol>
        <li>More than 30 activities that provide knowledge on water cylcle and water states, refine the child's senses through Montessori education, explore Froebel gifts and take advantage of Waldorf style of education</li>
        <li>List of useful books</li>
        <li>Pre - writing activities</li>
        <li>Arts and crafts activities</li>
        <li>Games</li>
        <li>Math activities</li>
        <li>Field trips suggestions</li>
        <li>Puzzles</li>
        <li>and more ...</li>
      </ol>
      <p class="card-text">You can download a PDF file sized to A4 ready to print.</p>
      <p class="card-text">This pack and more activities are availble at our website www.akindergarten.com.</p>
      <p class="card-text">The content is protected by copyright laws. The sharing or selling of this content is strictly prohibited.</p>
  </div>
    <UnitStudy v-for="unitStudy in unitStudies" :key="unitStudy.unit_study_id" v-bind:unitstudy="unitStudy"></UnitStudy>
  </section>
</template>
<script>
import UnitStudy from '@/components/UnitStudy'

export default {
  name: 'UnitStudies',
  data () {
    return {
      show: []
    }
  },
  components: {
    UnitStudy
  },
  computed: {
    unitStudies () {
      return this.$store.state.unitStudies
    },
    showing (id) {
      return this.expanded(id)
    }
  },

  mounted: function () {
    this.$store.dispatch('LOAD_UNIT_STUDIES')
    for (this.unitStudy in this.$store.state.unitStudies) {
      console.log(this.unitStudy.unit_study_id)
    }
  },

  methods: {
    expanded: function (id) {
      if (this.show[id] === 'true') {
        return true
      }
      return false
    },
    expand: function (id) {
      this.show[id] = 'true'
    },
    colapse: function (id) {
      this.show[id] = 'false'
    }
  }
}
</script>
<style>
</style>
