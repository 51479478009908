<template>
  <div>
    <PayPal class="paypal-customized"
      amount="15.00"
      currency="USD"
      :client="credentials"
      :button-style="myStyle"
      v-on:payment-authorized="paymentAuthorized"
      v-on:payment-completed="paymentCompleted"
      v-on:payment-cancelled="paymentCancelled"
      env="sandbox">
    </PayPal>
  </div>
</template>

<script>
import axios from 'axios'
import PayPal from 'vue-paypal-checkout'
export default {
  name: 'paypal',
  props: {
    username: '',
    password: ''
  },
  data () {
    return {
      credentials: {
        sandbox: 'Abv3nfUhdHOgNgFQ1WHnBMakp2d_wZ1uly6wntk8sNYw5c17osyR9EZWHpk0QS1OhlPnl9PvrCBL-rup',
        production: 'ASFMztmnJ8ySN4U77fbSaOUBxZxpKfexdGDFigoGrRbhGt4XPhtE0t7ZGXe14RhBaUbH8NXuarNCbM13'
      },
      amount: '15.00',
      currency: 'USD',
      myStyle: {
        label: 'checkout',
        size: 'medium',
        shape: 'pill',
        color: 'gold'
      }
    }
  },
  methods: {
    paymentAuthorized: function (data) {
    },
    paymentCompleted: function (data) {
      this.onCompleted(data.id)
    },
    paymentCancelled: function (data) {
    },
    onCompleted (id) {
      axios.post('/api/registration', { username: this.username, password: this.password, payment_id: id })
        .then(request => {
          if (typeof request.data.access_token !== 'undefined') {
            this.executeLogin(request.data.access_token)
          }
        })
        .catch(error => { console.log(error) })
    },
    onReset () {
      console.log('We should reset the form!')
    },
    executeLogin (token) {
      var payload = {'access_token': token}
      console.log(payload)
      this.$store.dispatch('set_token', payload)
      this.$store.dispatch('login')
      this.$router.push({name: 'home'})
    }
  },
  components: {
    PayPal
  }
}
</script>
