<template>
  <header>
    <nav id="navigation">
      <div id="nav-icon4" class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span></span>
          <span></span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <ul>
            <li><router-link to="/about">About "A kindergarten"</router-link></li>
            <li><router-link to="/articles">Articles</router-link></li>
            <li><router-link to="/unitstudies">Unit studies</router-link></li>
            <li><router-link to="/literature">Recommended books</router-link></li>
          </ul>
        </div>
      </div>
      <div>
        <a href="/#/"><img id="favicon" src="/static/img/favicon.png"></a>
      </div>
          <template v-if="is_authenticated">
            <div>
              <a :href="'/#/logout'">SIGN OUT</a>
            </div>
          </template>
          <template v-else>
            <div>
              <a :href="'/#/login'">SIGN IN</a>
            </div>
          </template>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'Navbar',
  computed: {
    is_authenticated () {
      return this.$store.state.auth.isAuthenticated
    }
  },

  methods: {
    login () {
      /*      this.$store.dispatch('login') */
    },
    logout () {
      this.$store.dispatch('logout')
      this.$store.dispatch('clear_token')
      this.$store.dispatch('CLEAR_EXERCISE_LIST')
      this.$store.dispatch('LOAD_FREE_EXERCISE_LIST')
      this.$router.push({name: 'home'})
    }
  }
}
</script>
<style>
</style>
