<template>
  <div id="app">
  <Navbar></Navbar>
  <router-view>
  </router-view>
  <Footer></Footer>
  </div>
</template>

<script>

import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  metaInfo: {
    title: 'A kindergarten: resources for early childhood education',
    meta: [
      { name: 'description', content: 'Everyday curriculum, based on well known methods of early childhood education (Montessori, Froebel, Steiner). All activities are screen-free, child-led, suitable for 2 and 3 year olds. The exercises develop gross and fine motor skills, problem solving, confidence, communication, vocabulary.' }
    ]
  },
  data () {
    return {
      exercises: []
    }
  },
  computed: {
    ex_types () {
      return this.$store.state.ex_types
    }
  },

  components: {
    Navbar,
    Footer
  }
}

</script>