<template>
  <div>
    <div id="sign-form">
    <template v-if="token">
      <h1>Please enter your new password</h1>
      <form @submit="onChange">
        <input id="emailInput"
          name="email"
          type="email"
          v-model="form.email"
          placeholder="Email address"
          autofocus>
        <input
          name="password"
          type="password"
          v-model="form.password1"
          placeholder="Password">
        <input
          name="password"
          type="password"
          v-model="form.password2"
          placeholder="Password confirmation">
        <button name="submitBtn" type="submit" value="reset">Update password</button>
      </form>
    </template>
    <template v-else>
      <h1>Reset your password</h1>
      <p>Please enter the email address associated with your account. We'll send you an email to help you reset your password.</p>
      <form @submit="onSubmit">
        <input id="emailInput"
              name="email"
              type="email"
              placeholder="Email"
              v-model="form.email"
              required
              autofocus>
        <button name="submitBtn" type="submit" value="submit">Send it</button>
      </form>
    </template>
    </div>
      <b-alert variant="danger" class="mt-2"
        dismissible
        :show="form.showAlert"
        @dismissed="form.showAlert=false">
        Email with instructions has been sent successfully!
      </b-alert>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Reset',
  props: ['token'],
  data () {
    return {
      form: {
        email: '',
        showAlert: false
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      let data = JSON.stringify({ 'username': this.form.email })
      console.log(data)
      axios.post('/api/reset', data)
    },
    onChange (evt) {
      evt.preventDefault()
      let data = JSON.stringify({ 'username': this.form.email, 'password': this.form.password1 })
      axios.post('/api/reset/' + this.token, data)
    }
  }
}
</script>
<style>
</style>
