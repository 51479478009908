<template>
<h1>Logout</h1>
</template>
<script>
export default {
  mounted () {
    this.$store.dispatch('logout')
    this.$store.dispatch('clear_token')
    this.$store.dispatch('CLEAR_EXERCISE_LIST')
    this.$store.dispatch('LOAD_FREE_EXERCISE_LIST')
    this.$router.push({name: 'home'})
  },
  name: 'Logout'
}
</script>
<style>
</style>
