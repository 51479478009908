<template>
  <section>
    <div class="card" autofocus>
      <div class="card-header" v-html="article.label"></div>
      <div class="card-body" v-if="show" v-html="article.content"></div>
      <div class="card-body" v-else v-html="article.description"></div>
      <div class="card-body art-link" v-if="show">
        <a v-on:click='change'><i class="red up"></i></a>
      </div>
      <div class="card-body art-link" v-else>
        <a v-on:click='change'><i class="red down"></i></a>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'Article',
  props: ['article'],
  data () {
    return {
      show: false
    }
  },
  methods: {
    change () {
      this.show = !this.show
    }
  }
}
</script>
<style>
</style>
