<template>
  <section>
    <b-card-header>{{ processedExercises[currentEx].label }}</b-card-header>
    <b-card :class="color">
      <p class="card-text">{{ processedExercises[currentEx].description }}</p>
      <div class="activity-containers" v-if="(processedExercises[currentEx].pdf && processedExercises[currentEx].img)">
        <div class="activity-pdf-container pdf-img-inlined" v-html="format_pdfs('inlined')">
        </div>
        <div class="activity-img-container pdf-img-inlined" v-html="format_pics('inlined')">
        </div>
      </div>
      <div class="activity-containers" v-else>
        <div class="activity-pdf-container" v-if="processedExercises[currentEx].pdf" v-html="format_pdfs('default')">
        </div>
        <div class="activity-img-container" v-if="processedExercises[currentEx].img" v-html="format_pics('default')">
        </div>
      </div>
      <div>
        <b-button variant="secondary" :pressed="false" v-on:click="previous"><i :class="[color,'i-left']"></i></b-button>
        <b-button variant="secondary" :pressed="false" v-on:click="next" :class="'i-right'"><i :class="color"></i></b-button>
      </div>
    </b-card>
  </section>
</template>

<script>

export default {
  name: 'ExerciseInstance',
  props: ['exercises', 'type', 'color'],
  data () {
    return {
      currentEx: 0
    }
  },
  computed: {
    processedExercises () {
      var pexs = this.getExercises(this.type)
      return pexs
    }
  },
  methods: {
    getExercises (exType) {
      var exTypes = []
      for (var ex in this.exercises) {
        if (this.exercises[ex].type === exType) {
          exTypes.push(this.exercises[ex])
        }
      }
      return exTypes
    },
    format_pdfs (sw) {
      var html = ''
      var pdfs = this.processedExercises[this.currentEx].pdf.split('|')
      var alt = this.processedExercises[this.currentEx].pdfalt
      html += '<figure>'
      for (var i in pdfs) {
        if (sw === 'inlined') {
          html += '<div class="pdf_files pdf-img-inlined">'
        } else {
          html += '<div class="pdf_files">'
        }
        html += '<a target="_blank" href="/static/pdf/' + pdfs[i] + '">'
        html += '<img src="/static/img/small_pdf_' + pdfs[i].slice(0, -4) + '.jpg" alt="' + alt + '" class="card-img-bottom img-rounded img-fluid"></a>'
        html += '<img class="pdf" src="/static/img/pdficon.png"></div>'
      }
      html += '<figcaption>' + alt + '</figcaption></figure>'
      return html
    },
    format_pics (sw) {
      var html = ''
      var pics = this.processedExercises[this.currentEx].img.split('|')
      var alts = this.processedExercises[this.currentEx].alt.split('|')
      for (let i = 0; i < pics.length; i += 3) {
        if (sw === 'inlined') {
          html += '<div class="activity-img pdf-img-inlined">'
        } else {
          html += '<div class="activity-img">'
        }
        html += '<figure>'
        html += '<a target="_blank" href="/static/img/' + pics[i] + '">'
        html += '<img src="/static/img/small_' + pics[i] + '" alt="' + alts[i] + '" class="card-img-bottom img-rounded img-fluid"></a>'
        html += '<figcaption>' + alts[i] + '</figcaption></figure>'
        if (pics[i + 1] !== undefined) {
          html += '<figure>'
          html += '<a target="_blank" href="/static/img/' + pics[i + 1] + '">'
          html += '<img src="/static/img/small_' + pics[i + 1] + '" alt="' + alts[i + 1] + '" class="card-img-bottom img-rounded img-fluid"></a>'
          html += '<figcaption>' + alts[i + 1] + '</figcaption></figure>'
        }
        if (pics[i + 2] !== undefined) {
          html += '<figure>'
          html += '<a target="_blank" href="/static/img/' + pics[i + 2] + '">'
          html += '<img src="/static/img/small_' + pics[i + 2] + '" alt="' + alts[i + 2] + '" class="card-img-bottom img-rounded img-fluid"></a>'
          html += '<figcaption>' + alts[i + 2] + '</figcaption></figure>'
        }
        html += '</div>'
      }
      return html
    },
    next: function (event) {
      if (this.$store.state.auth.isAuthenticated) {
        var payload = {'token': this.$store.state.auth.token, 'type': this.type}
        this.$store.dispatch('LOAD_NEXT_EXERCISE_LIST', payload)
      } else {
        if (this.currentEx < this.processedExercises.length - 1) {
          this.currentEx += 1
        } else {
          this.$router.push({name: 'membership'})
        }
      }
    },
    previous: function (event) {
      if (this.$store.state.auth.isAuthenticated) {
        var payload = {'token': this.$store.state.auth.token, 'type': this.type}
        this.$store.dispatch('LOAD_PREVIOUS_EXERCISE_LIST', payload)
      } else {
        if (this.currentEx >= 1) {
          this.currentEx -= 1
        } else {
          this.currentEx = 0
        }
      }
    }
  }
}
</script>
