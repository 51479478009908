<template>
  <footer>
    <ul class="footer-list">
      <li><a :href="'/#/about'"> About Us </a></li>
      <li><a :href="'/#/privacy'"> Privacy policy </a></li>
      <li><a :href="'https://www.facebook.com/groups/367339767328200/'" target="_blank">Facebook</a></li>
      <li><a :href="'/#/contact'">Contact</a></li>
    </ul>
    <span id="copyright">&copy; 2018 - 2021 InteWect LTD - All Rights Reserved</span>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
</style>
