// src/vuex/store.js
import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import backend from '@/backend/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    free_exercises: [],
    paid_exercises: [],
    current_exercises: [],
    exercises: [],
    ex_types: 'Practical, Crafts, Rhythm, Senses, Architecture, Language, Math, Gross'.split(', '),
    articles: [],
    unitStudies: []
  },
  actions: {
    LOAD_FREE_EXERCISE_LIST: function ({ commit }) {
      backend().get('/api/get_free_exercises').then((response) => {
        commit('SET_FREE_EXERCISE_LIST', response.data.exercises)
        commit('exercises')
      }, (err) => {
        console.log(err)
      })
    },
    LOAD_PAID_EXERCISE_LIST: function ({ commit }, payload) {
      backend().get('/api/get_paid_exercises', { headers: { Authorization: payload.token } }).then((response) => {
        commit('SET_PAID_EXERCISE_LIST', response.data.exercises)
        commit('exercises')
      }, (err) => {
        console.log(err)
      })
    },
    LOAD_CURRENT_EXERCISE_LIST: function ({ commit }, payload) {
      backend().get('/api/get_current_exercises', { headers: { Authorization: payload.token } }).then((response) => {
        commit('clearExercises')
        commit('SET_CURRENT_EXERCISE_LIST', response.data.exercises)
        commit('exercises')
      }, (err) => {
        console.log(err)
      })
    },
    LOAD_NEXT_EXERCISE_LIST: function ({ commit }, payload) {
      backend().get('/api/next_exercise_by_type/' + payload.type, { headers: { Authorization: payload.token } }).then((response) => {
        commit('clearExercises')
        commit('SET_CURRENT_EXERCISE_LIST', response.data.exercises)
        commit('exercises')
      }, (err) => {
        console.log(err)
      })
    },
    LOAD_PREVIOUS_EXERCISE_LIST: function ({ commit }, payload) {
      backend().get('/api/previous_exercise_by_type/' + payload.type, { headers: { Authorization: payload.token } }).then((response) => {
        commit('clearExercises')
        commit('SET_CURRENT_EXERCISE_LIST', response.data.exercises)
        commit('exercises')
      }, (err) => {
        console.log(err)
      })
    },
    CLEAR_EXERCISE_LIST: function ({ commit }) {
      commit('clearExercises')
    },
    LOAD_ARTICLES: function ({ commit }) {
      backend().get('/api/get_articles').then((response) => {
        commit('SET_ARTICLES', response.data.articles)
      }, (err) => {
        console.log(err)
      })
    },
    LOAD_UNIT_STUDIES: function ({ commit }) {
      backend().get('/api/get_unit_studies').then((response) => {
        commit('SET_UNIT_STUDIES', response.data.unit_studies)
      }, (err) => {
        console.log(err)
      })
    }
  },
  mutations: {
    SET_FREE_EXERCISE_LIST (state, exercises) {
      state.free_exercises = exercises
    },
    SET_PAID_EXERCISE_LIST (state, exercises) {
      state.paid_exercises = exercises
    },
    SET_CURRENT_EXERCISE_LIST (state, exercises) {
      state.current_exercises = exercises
    },
    SET_ARTICLES (state, articles) {
      state.articles = articles
    },
    SET_UNIT_STUDIES (state, unitStudies) {
      state.unitStudies = unitStudies
    },
    clearExercises (state) {
      state.free_exercises = []
      state.paid_exercises = []
      state.current_exercises = []
      state.exercises = []
    },
    clearArticles (state) {
      state.articles = []
    },
    clearUnitStudies (state) {
      state.unitStudies = []
    },
    exercises (state) {
      state.exercises = state.free_exercises.concat(state.current_exercises)
    }
  }
})
