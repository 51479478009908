<template>
  <div id="contact-form">
    <form @submit="onSubmit">
      <input name="email" type="email" placeholder="email" required="" v-model="form.email" autofocus>
      <textarea name="message" placeholder="Type your Message Here...." maxlength="500" v-model="form.message" required=""></textarea>
      <button name="contactBtn" type="submit" value="reset">Submit</button>
    </form>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'Contact',
  data () {
    return {
      form: {
        email: '',
        message: ''
      }
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      let data = JSON.stringify({ 'email': this.form.email, 'message': this.form.message })
      axios.post('/api/contact', data)
      evt.target.reset()
    }
  }
}
</script>
<style>
</style>
