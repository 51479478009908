<template>
  <section>
    <Article v-for="article in articles" :key="article.article_id" v-bind:article="article"></Article>
  </section>
</template>
<script>
import Article from '@/components/Article'

export default {
  name: 'Articles',
  data () {
    return {
      show: []
    }
  },
  components: {
    Article
  },
  computed: {
    articles () {
      return this.$store.state.articles
    },
    showing (id) {
      return this.expanded(id)
    }
  },

  mounted: function () {
    this.$store.dispatch('LOAD_ARTICLES')
    for (this.article in this.$store.state.articles) {
      console.log(this.article.article_id)
    }
  },

  methods: {
    expanded: function (id) {
      if (this.show[id] === 'true') {
        return true
      }
      return false
    },
    expand: function (id) {
      this.show[id] = 'true'
    },
    colapse: function (id) {
      this.show[id] = 'false'
    }
  }
}
</script>
<style>
</style>
