<template>
  <div class='footer-links'>
    <h2>Terms And Conditions And Our Commitment To Your Privacy</h2>
    <p>
      Your privacy is important to us. To better protect your privacy the following notice
      describes the information we collect, how it is used, how it is safeguarded, and how to
      contact us if you have any concerns.
    </p>
    <p>
      By using, registering to or otherwise accessing this website, you agree to this privacy
      policy and give an explicit and informed consent to the processing, use and disclosure of
      your personal data in accordance with this privacy policy. If you do not agree to this
      privacy policy, please don't use, register to or otherwise access this website.
    </p>
    <p>
      We collect the information you give us. If you create an account, we'll ask for your email
      address to store it with your account, in order to provide you with access to this website.
      We collect and store information in server logs (like device event information). This
      information is used for registration and identification. We will not share this information
      with third parties, except for when it is required by government officials in connection with
      illegal activity, or if we believe that this is necessary to protect our rights, combat fraud
      and/or comply with a judicial proceeding, court order or legal process served on this website
      or our team (team member/s).
    </p>
    <p>
      We retain the data collected pursuant to this privacy policy for the period necessary to
      fulfill the purposes outlined in this privacy policy unless a longer retention period is
      required or permitted by law. We do not verify the correctness of personal data.
    </p>
    <p>
      You will not receive any emails from this website, other than a confirmation of your
      registration or in case you have forgotten your password. We may process your personal
      information on a server located outside the country you live in. Personal data collected by
      this website is stored in secure operating environments. However, no system can be 100% secure
      and there is the possibility there could be unauthorized access to your personal data. By
      using this website, you assume this risk.
    </p>
    <p>
      Purchases will be done via <a href ="http://www.paypal.com">PayPal</a>. “A kindergarten” website will not collect any
      payment information.
    </p>
    <p>
      You recognize and agree that this website is not liable for any third party terms and
      conditions and their use of your personal data.
    </p>
    <p>
      If we receive a formal written complaint from you, we will attempt to contact you directly to
      address any of your concerns. We will cooperate with the appropriate governmental authorities
      to resolve any complaints regarding personal data that cannot be resolved between you and this
      website. Any dispute relating to this privacy policy shall be settled at our discretion.
    </p>
    <p>
      This policy will be updated regularly, so please review it frequently. Your continued use of
      this website will signify your acceptance of the changes to this privacy policy.
    </p>
    <p>
      Any and all use of your personal data by this website is governed by law of the Republic of
      Bulgaria and not the laws where you may be located.
    </p>
    <p>
      The content of this website cannot be shared. You are responsible for keeping your password secret and secure. You may not use this website and its content for any unautorized purpose. You agree to comply with all laws, rules, and regulations (for example, state, local) aplicable to your use of the website and its content including, but not limited to, copyright laws. The sharing or selling of username(s) is strictly prohibited and may result in the removal of a user's account from the system.
    </p>
  </div>
</template>
<script>
export default {
  name: 'Privacy'
}
</script>
<style>
</style>
