import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import $ from 'jquery'
import Exercises from '@/components/Exercises'
import Login from '@/components/Login'
import Logout from '@/components/Logout'
import Membership from '@/components/Membership'
import About from '@/components/About'
import Privacy from '@/components/Privacy'
import Contact from '@/components/Contact'
import Articles from '@/components/Articles'
import Reset from '@/components/Reset'
import Literature from '@/components/Literature'
import UnitStudies from '@/components/UnitStudies'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Exercises
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout
    },
    {
      path: '/membership',
      name: 'membership',
      component: Membership
    },
    {
      path: '/reset/:token?',
      name: 'reset',
      component: Reset,
      props: true
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      beforeEnter: (to, from, next) => {
        if ($('#nav-icon4').hasClass('open')) {
          $('#nav-icon4').toggleClass('open show')
          $('.dropdown-menu').toggleClass('show')
        }
        next()
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/articles',
      name: 'articles',
      component: Articles,
      beforeEnter: (to, from, next) => {
        if ($('#nav-icon4').hasClass('open')) {
          $('#nav-icon4').toggleClass('open show')
          $('.dropdown-menu').toggleClass('show')
        }
        next()
      }
    },
    {
      path: '/unitstudies',
      name: 'unitstudies',
      component: UnitStudies,
      beforeEnter: (to, from, next) => {
        if ($('#nav-icon4').hasClass('open')) {
          $('#nav-icon4').toggleClass('open show')
          $('.dropdown-menu').toggleClass('show')
        }
        next()
      }
    },
    {
      path: '/literature',
      name: 'literature',
      component: Literature,
      beforeEnter: (to, from, next) => {
        if ($('#nav-icon4').hasClass('open')) {
          $('#nav-icon4').toggleClass('open show')
          $('.dropdown-menu').toggleClass('show')
        }
        next()
      }
    }
  ]
})
