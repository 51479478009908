<template>
  <section>
    <div class="card-body">
      <template v-if="is_authenticated">
        <p class="card-text">Theme: {{ unitstudy.name }}
          <a class="red_links" target="_blank" :href="'/static/pdf/' + unitstudy.pdf"> - Download</a>
        </p>
      </template>
      <template v-else>
        <p class="card-text">Theme: {{ unitstudy.name }}
          <a class="red_links" target="_blank" :href="'/#/membership'"> - Download</a>
        </p>
      </template>
      <div class="activity-img">
        <figure>
          <a target="_blank" :href="'/static/img/' + unitstudy.large_jpg1">
          <img :src="'/static/img/' + unitstudy.small_jpg1" class="card-img-bottom img-rounded img-fluid"></a>
        </figure>
        <figure>
          <a target="_blank" :href="'/static/img/' + unitstudy.large_jpg2">
          <img :src="'/static/img/' + unitstudy.small_jpg2" class="card-img-bottom img-rounded img-fluid"></a>
        </figure>
        <figure>
          <a target="_blank" :href="'/static/img/' + unitstudy.large_jpg3">
          <img :src="'/static/img/' + unitstudy.small_jpg3" class="card-img-bottom img-rounded img-fluid"></a>
        </figure>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'UnitStudy',
  props: ['unitstudy'],
  data () {
    return {
      show: false
    }
  },
  computed: {
    is_authenticated () {
      return this.$store.state.auth.isAuthenticated
    }
  },
  methods: {
    change () {
      this.show = !this.show
    }
  }
}
</script>
<style>
</style>
